@import '~antd/dist/antd.css';

body {
  --primary-color: #f7931e;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

ul{
  padding: 0 0 0 20px;
}

li::marker{
  color: var(--primary-color);
}

/* ANT-DESIGN STYLE */

.ant-switch {
  margin: 0 !important;
}

.ant-popover-inner-content {
  width: 400px;
}

.ant-image-preview-img {
  background-color: white;
  padding: 15px;
  border-radius: 4px;
}

.ant-image-img.qrcode-img {
  background-color: white;
  padding: 3px;
  border-radius: 4px;
}

.ant-switch-checked {
  background-color: var(--primary-color);
}

.center .ant-modal-footer{
  display: flex;
  justify-content: center;
}